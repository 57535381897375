import React from "react";
import "../../styles/Page.scss";
import Layout from "../../components/Layout";
import PageInfo from "../../components/PageInfo";

import accesrandom from "../../content/advanced/images/acces-schema-etude.png";
import choixrandom from "../../content/advanced/images/choixrandomisation.png";
import ajoutergroupe from "../../content/advanced/images/ajouter-groupe.png";
import bouton from "../../content/advanced/images/randomize.png";

export default function FeatRandomization() {
  return (
    <Layout title="Randomisation">
      <div className="page-body">
        <PageInfo AdmLo AdmGl />
        <div className="page-title">
          <h1>La randomisation</h1>
        </div>
        <section>
          <h2 id="définir-la-randomisation-d-une-étude">
            Définir la randomisation d'une étude
          </h2>
          <p>
            Pour configurer les paramètres relatifs à la randomisation,
            rendez-vous dans le menu <b>Conception</b> puis dans l'onglet{" "}
            <b>Schéma d'étude</b>.
          </p>
          <img
            src={accesrandom}
            alt="Onglet schéma d'étude"
            className="centered"
          />
          <p>
            Dotter.science permet d'effectuer 3 types de randomisation :
            <li>la randomisation simple</li>
            <li>la randomisation par blocs (taille fixe)</li>
            <li>la randomisation par blocs (taille variable)</li>
          </p>
          <p>
            Pour choisir le mode de randomisation souhaité, cliquez sur{" "}
            <b>Choix d'une randomisation</b> (1) et sélectionnez celle que vous
            souahitez. Dans le cas d'une randomisation par blocs, des champs
            supplémentaires apparaissent pour indiquer la taille des blocs (2).
          </p>
          <img
            src={choixrandom}
            alt="Choix de la randomisation"
            className="centered"
          />
        </section>
        <section>
          <h2 id="créer-des-groupes">Créer des groupes</h2>
          <p>
            Pour créer les groupes dans lesquels seront randomisés les patients,
            survolez la partie <b>Gestion des groupes</b> et cliquez sur le
            bouton "<b>+</b>" qui apparaît. Une fenête s'ouvre alors afin de
            renseigner le nom du groupe. Il est également possible d'ajouter un
            nom court et une description à chaque groupe mais ces informations
            ne sont pas obligatoires, elles pourront être ajoutées plus tard.
          </p>
          <img
            src={ajoutergroupe}
            alt="Ajouter un groupe"
            className="centered"
          />
        </section>
        <section>
          <h2 id="randomiser-un-patient">Randomiser un patient</h2>
          <p>
            Si vos groupes ont été préalablement créés, accédez à l'eCRF puis
            aux données d'un patient. En bas de l'écran s'affiche un bouton "
            <b>Randomiser</b>", lorsque vous cliquerez dessus, une fenêtre de
            confirmation s'affiche alors. Validez pour randomiser le patient et
            obtenir le groupe auquel il a été attribué.
          </p>
          <img src={bouton} alt="Randomiser un patient" className="centered" />
        </section>
      </div>
    </Layout>
  );
}
